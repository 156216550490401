<script lang="ts" setup></script>

<template>
  <div class="px-4">
    <div class="container flex flex-col gap-8 pt-16 md:flex-row md:pt-24">
      <div class="flex flex-col gap-8 text-center md:-mt-4 md:w-1/3 md:pt-0 lg:text-left xl:pt-32">
        <UIHeading :htmlText="$t('general.landing.careerCoachByYourSide')" class="mx-auto mt-9 w-10/12 font-thin lg:mx-0" size="h2" styleSize="h2" />
        <p>
          {{ $t('general.landing.poweredByAi') }}
          <UIAssetIcon class="shrink-0" path="sparkle" size="md" />
        </p>
        <div>
          <UIButton :text="$t('general.landing.startTalking')" customClasses="w-fit mx-auto md:mx-0 " to="/onboarding" />
        </div>
      </div>
      <div class="text-center md:w-2/3">
        <NuxtImg alt="" class="mt-2 w-full md:-mt-20" preload role="presentation" src="/images/landing/coach.webp" />
      </div>
    </div>
  </div>
</template>
